@use '@material/textfield/mdc-text-field';
/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html,
body {
    height: 100%;
    padding-top: 0;
}

main {
    margin-top: 130px;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

a {
    color: #3072be;
}

a.link {
    color: #3072be !important;
    background-color: inherit !important;
    ;
}

.form-controls {
    margin-top: 15px;
}

h2.page-title {
    font-size: 24px;
    font-weight: 600;
}

h3 {
    font-size: 24px;
}

label {
    font-size: 16px;
    font-weight: bold;
}

.arrow {
    border: solid #3072be;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}


.mat-mdc-tooltip {
    font-size: 14px;
}

.table-responsive {
    overflow-x: auto;
}

input.ng-touched.ng-invalid {
    border: red solid 2px;
}

.required-asterisk,
.tip {
    color: red;
}

.btn {
    border-radius: 150px;
    padding: 5px 20px;
    border: none;
}

.btn-primary {
    background-color: rgba(48, 114, 190, 1);
}

.btn-function {
    background-color: rgb(255, 255, 255);
    color: rgba(48, 114, 190, 1);
    border: 1px solid rgba(48, 114, 190, 1);
    font-weight: bold;
}



.container {
    padding-bottom: 150px;

}

.bs-media-container {
    background: #fff;
}

.grey {
    background-color: rgba(240, 240, 240, 1);
}

.modal-dialog {
    max-width: 85%;
}

.toggle-state {
    font-size: 40px;
}

.dropdown-menu {
    border-top: 2px solid #3072Be;
    width: max-content;
}

.k-grid-header .k-header {
    background-color: #fff;
    font-weight: bold;
    border: 0.2pt solid #99999f;
}

.k-grid-header .k-header:first-child {
    border: 0.2pt solid #99999f;
}

.k-grid tr.k-alt {
    background-color: #fff;
    border: 0.2pt solid #99999f;
}

.k-grid-header,
.k-header,
th.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>td,
.k-filter-row>th {
    border: 0.2pt solid #99999f;
}

.k-grid th:first-child,
.k-grid td:first-child {
    border: 0.2pt solid #99999f;
}


.k-grid th,
.k-grid td {
    padding: 4px;
}

.k-checkbox {
    border-color: rgba(0, 0, 0, 1);
    color: transparent;
    background-color: #fff;
}

/* table styles */

.mat-sort-header-content {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.mat-mdc-row {
    display: table-row;
    font-family: 'Open Sans';
    font-size: 16px;
}

table thead {
    background: #fff !important;
}

.mat-mdc-paginator-page-size-label {
    font-weight: bold;
    font-family: 'Open Sans';
    font-size: 16px;
    padding-right: 16px;
}

.mat-elevation-z8 {
    box-shadow: none;
}

tr.mat-mdc-header-row {
    height: 56px;
    border-bottom: 1px solid #999;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
    border-right: 1px solid #999;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
    border-right: 1px solid #999;
}

.mat-mdc-paginator-container {

    justify-content: space-between !important;

}


.mat-mdc-table {
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 566px;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    border-right: 1px #999 solid;

}

.mat-mdc-row,
.mat-mdc-header-row {
    display: table-row;
    border-bottom: 1px #999 solid;
}

.mat-elevation-z8 {
    overflow-y: scroll;
    max-width: 100%;
}

.header {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: none !important;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.table tbody tr td {
    border-bottom: #999 1px solid;
    border-right: 1px solid #999 !important;
}

.table th,
.table td {

    vertical-align: middle !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    border-right: 1px solid #999 !important;
}

.table thead th {
    padding-left: 24px;
    padding-right: 24px;
    border-right: 1px solid #999 !important;
}

/**** Loading Indicator start ****/
.dimBackground-gray {
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: 9998;
    transition: opacity 0.5s ease-in-out;
}

.dimBackground-blue {
    top: 0;
    position: fixed;
    background-color: #5b88ad99;
    height: 100%;
    width: 100%;
    z-index: 9998;
    transition: opacity 0.5s ease-in-out;
}

.spinner {
    pointer-events: none;
    top: 18.75rem;
    left: 0;
    right: 0;
    position: fixed;
    text-align: center;
    color: #f5f5f5;
    z-index: 9998;
}

@media (min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1422px;
        margin-left: 97px;
        padding-top: 20px;
    }
}

.breadcrumb {
    padding-left: 130px;
    color: #3072be;
    background-color: rgb(242, 242, 242);
    padding-top: 8px;
    padding-bottom: 8px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: inherit !important;
}

.mdc-dialog .mdc-dialog__surface {
    overflow-y: initial;
}

#dropdown-arrow-mat {

    .mat-mdc-select-trigger {
        width: 106%;
    }

    .mat-mdc-select-arrow-wrapper {
        display: none;
    }
}

.mat-mdc-text-field-wrapper {
    background-color: #fff !important;
}

.mat-mdc-floating-label {
    top: 35px !important;
}

.mdc-floating-label {
    padding-bottom: 15px;
}

// show label for more dense inputs as well
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
    display: inline !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
    width: 97% !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--mdc-theme-error, #f44336) !important;
}


.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #0d6efd;
    --mdc-checkbox-selected-hover-icon-color: #0d6efd;
    --mdc-checkbox-selected-icon-color: #0d6efd;
    --mdc-checkbox-selected-pressed-icon-color: #0d6efd;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
}

.custom-mat-date-range-input {
    display: flex !important;
}

.mat-mdc-card {
    box-shadow: none !important;
}

div.recon-table-status {
    background-color: inherit !important;
}


/**** Loading Indicator start ****/
